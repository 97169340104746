<template>
    <div class=" mx-0 my-0">
   
    <div > 
        <!-- <v-divider></v-divider> -->
        <!-- <v-tabs
        :dark="$store.getters.getColorPalette().isDark"
      v-model="tab"
      :background-color="$store.getters.getColorPalette().tab"
      show-arrows
      grow
    > -->
    <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/reports/generatedReports'">
      <!-- <v-tab 
         :dark="$store.getters.getColorPalette().isDark"
        
    
      >
        Reports
      </v-tab> -->
    </router-link>
      <!-- <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/reports/customReports'">
      <v-tab    :dark="$store.getters.getColorPalette().isDark"
        
     :color="$store.getters.getColorPalette().btnborderColorCode">
          Trend Analysis Tools
      </v-tab>
    </router-link> -->
      <!-- <router-link :style="{'color':'inherit','display': 'flex','justify-content':'space-between','align-item':'center','text-decoration': 'none'}" :to="'/reports/compareReports'">
      <v-tab    :dark="$store.getters.getColorPalette().isDark"
        
     :color="$store.getters.getColorPalette().btnborderColorCode">
          Compare
      </v-tab>
    </router-link> -->
    <!-- </v-tabs> -->

    <!-- <v-tabs-items v-model="tab"  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode" class="grey lighten-5 indigo--text text-capitalize"> -->
      <!-- <v-tab-item
        
      >
        <v-card
        
          :color="$store.getters.getColorPalette().backgroundColorCode"
          flat
        >
        <v-row no-gutters v-if="$store.state.parameters.length>0 && $store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) ">
          <v-col v-if="$store.getters.getAccessByLevels(['betaaccess'])" >
            <CreateParameterReportBottomSheet />
          </v-col>
          <v-col>
            <CreateUsageReportBottomSheet />
          </v-col>
          <v-col>
            <CreateEnergyReportBottomSheet />
          </v-col>
          <v-col>
            <CreateEnergyUsageReportBottomSheet />
          </v-col>
          <v-col>
            <CreateEnergyDistributionReportBottomSheet />
          </v-col>
          <v-col>
            <CreateEnergyBillDistReportBottomSheet />
          </v-col>
          <v-col>
            <CreateParameterSECReportBottomSheet />
          </v-col>
          <v-col v-if="$store.getters.getAccessByLevels(['betaaccess'])" >
            <CreateGranularSECReportBottomSheet />
          </v-col>
          <v-col>
            <CreateSECReportBottomSheet />
          </v-col>
          <v-col >
            <CreateConsolidatedReportBottomSheet />
          </v-col>
          <v-col >
            <CreateDynamicExcelReportBottomSheet />
          </v-col>
        </v-row>
        <v-row >
          <v-col cols="12" >
            <ReportList />
          </v-col>
        </v-row>
          <v-row v-if="$store.state.snodes.length>0">
            <v-col>
                <SNodeGroupConsumptionPanel :snodes="$store.state.snodes" />
            </v-col>
        </v-row>
        
        <v-row v-if="$store.state.nodes.length>0">
            <v-col cols="12">
                <MonthlyConsumptionGraph v-if="$store.state.nodes.length>0" />
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ProductionLoggerList />
          </v-col>
        </v-row>
        </v-card>
      </v-tab-item> -->
      <!-- <v-tab-item :dark="$store.getters.getColorPalette().isDark">
          <v-card
          :color="$store.getters.getColorPalette().backgroundColor"
          flat
        > -->
        <GenerateReportPage />
          <!-- </v-card> -->
          
      <!-- </v-tab-item> -->
      <!-- <v-tab-item :dark="$store.getters.getColorPalette().isDark">
          <v-card
           :color="$store.getters.getColorPalette().backgroundColor"
          flat
        >
        <CustomReportsPage />
          </v-card>
          
      </v-tab-item> -->
      <!-- <v-tab-item
       :dark="$store.getters.getColorPalette().isDark"
      >
        <v-card
          :color="$store.getters.getColorPalette().backgroundColor"
          flat
        >
       <CompareParamsPage/>
        </v-card>
      </v-tab-item> -->
      
    <!-- </v-tabs-items> -->

        
        <v-divider></v-divider>
        
        
        
        
    </div>
    </div>
</template>

<script>
//import NodeLastXConsumedGraph from '@/components/panels/NodeLastXConsumedGraph'
//import SNodeUsageGraphByDays from '@/components/panels/SNodeUsageGraphByDays'
//import ParameterUsageGraphByDays from '@/components/panels/ParameterUsageGraphByDays'
//import ParameterGraphByDays from '@/components/panels/ParameterGraphByDays'
//import ParameterTODPanel from '@/components/panels/ParameterTODPanel'
//import SNodeTODPanel from '@/components/panels/SNodeTODPanel'



// import MonthlyConsumptionGraph from '@/components/panels/MonthlyConsumptionGraph'
// import SNodeGroupConsumptionPanel from '@/components/panels/SNodeGroupConsumptionPanel'
// import CreateParameterReportBottomSheet from '@/components/modals/CreateParameterReportBottomSheet'
// import CreateEnergyUsageReportBottomSheet from '@/components/modals/CreateEnergyUsageReportBottomSheet'
// import CreateEnergyReportBottomSheet from '@/components/modals/CreateEnergyReportBottomSheet'
// import CreateEnergyDistributionReportBottomSheet from '@/components/modals/CreateEnergyDistributionReportBottomSheet'
// import CreateEnergyBillDistReportBottomSheet from '@/components/modals/CreateEnergyBillDistReportBottomSheet'
// import CreateParameterSECReportBottomSheet from '@/components/modals/CreateParameterSECReportBottomSheet'
// import CreateGranularSECReportBottomSheet from '@/components/modals/CreateGranularSECReportBottomSheet'
// import CreateSECReportBottomSheet from '@/components/modals/CreateSECReportBottomSheet'
// import CreateConsolidatedReportBottomSheet from '@/components/modals/CreateConsolidatedReportBottomSheet'
// import CreateDynamicExcelReportBottomSheet from '@/components/modals/CreateDynamicExcelReportBottomSheet'
// import CreateUsageReportBottomSheet from '@/components/modals/CreateUsageReportBottomSheet'
// import ReportList from '@/components/lists/ReportList'
// import ProductionLoggerList from '@/components/lists/ProductionLoggerList'

// import CustomReportsPage from '@/pages/CustomReportsPage'
// import CompareParamsPage from '@/pages/CompareParamsPage'
import GenerateReportPage from '@/pages/GenerateReportPage'
export default {
    name:'Reports',
    components:{
      // CustomReportsPage,
      // CompareParamsPage,
      GenerateReportPage
        //NodeLastXConsumedGraph,
        //SNodeUsageGraphByDays,
        //ParameterUsageGraphByDays,
        //ParameterGraphByDays,
        //ParameterTODPanel,
        //SNodeTODPanel,
        
        // MonthlyConsumptionGraph,
        // SNodeGroupConsumptionPanel,
        // CreateParameterReportBottomSheet,
        // CreateEnergyUsageReportBottomSheet,
        // CreateEnergyReportBottomSheet,
        // CreateEnergyDistributionReportBottomSheet,
        // CreateEnergyBillDistReportBottomSheet,
        // CreateParameterSECReportBottomSheet,
        // CreateGranularSECReportBottomSheet,
        // CreateSECReportBottomSheet,
        // CreateConsolidatedReportBottomSheet,
        // CreateDynamicExcelReportBottomSheet,
        // CreateUsageReportBottomSheet,
        // ReportList,
        // ProductionLoggerList
    },
    mounted() {
      document.title='Reports'
         if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }else{
      this.$router.push('/reports/generatedReports');
    }
    },
    data(){
        return {
            tab:null,
            
        }
    }
}
</script>
<style scoped>
.row{
  margin-left: -13px;
  margin-right: 10px;
}


</style>